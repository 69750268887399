<template>
  <div class="container-fluid">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">User Management</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form class="parsley-examples">
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="name" class="form-label">Name</label>
                    <input type="text" id="name" name="name" class="form-control" placeholder="Name" v-model="user.name"
                      :class="{
                        'p-invalid': v$.user.name.$error || errorFor('name'),
                      }" autocomplete="off" disabled />
                    <v-errors :serverErrors="errorFor('name')" :vuelidateErrors="{
                      errors: v$.user.name.$errors,
                      value: 'Name',
                    }"></v-errors>
                  </div>

                  <!-- <div class="mb-3">
                    <label for="email" class="form-label">Email</label>
                    <input
                      type="text"
                      id="email"
                      name="email"
                      class="form-control"
                      placeholder="Email"
                      v-model="user.email"
                      disabled
                    />
                  </div> -->

                  <div class="mb-3">
                    <label for="employee_id" class="form-label">Employee Number</label>
                    <input type="text" id="employee_id" name="name" class="form-control" placeholder="Employee Number"
                      v-model="user.employee_id" autocomplete="off" disabled />
                  </div>

                  <!-- <div class="mb-3">
                    <label for="password" class="form-label">Password</label>
                    <input
                      type="password"
                      id="password"
                      name="password"
                      class="form-control"
                      v-model="user.password"
                      :class="{
                        'p-invalid':
                          v$.user.password.$error || errorFor('password'),
                      }"
                    />
                    <v-errors
                      :serverErrors="errorFor('password')"
                      :vuelidateErrors="{
                        errors: v$.user.password.$errors,
                        value: 'Password',
                      }"
                    ></v-errors>
                  </div>
                  <div class="mb-3">
                    <label for="confirm_password" class="form-label"
                      >Confirm Password</label
                    >
                    <input
                      type="password"
                      id="confirm_password"
                      name="confirm_password"
                      class="form-control"
                      v-model="user.confirm_password"
                      :class="{
                        'p-invalid':
                          v$.user.confirm_password.$error ||
                          errorFor('confirm_password'),
                      }"
                    />
                    <v-errors
                      :serverErrors="errorFor('confirm_password')"
                      :vuelidateErrors="{
                        errors: v$.user.confirm_password.$errors,
                        value: 'Confirm password',
                      }"
                    ></v-errors>
                  </div> -->
                </div>
                <!-- end col -->

                <div class="col-md-6">
                  <h4 class="header-title mt-5 mt-sm-0">
                    Assign Roles To User
                  </h4>
                  <div class="mt-2">
                    <div class="form-check" v-for="(role, index) in roles" :key="`role${index}`">
                      <input type="checkbox" class="form-check-input" :id="`role-${index}`" :value="role"
                        v-model="user.roles" />
                      <label class="form-check-label" :for="`role-${index}`">{{
                        role
                      }}</label>
                    </div>
                    <v-errors :serverErrors="errorFor('roles')" :vuelidateErrors="{
                      errors: v$.user.roles.$errors,
                      value: 'Roles',
                    }"></v-errors>
                  </div>
                </div>
                <!-- end col -->
              </div>
              <!-- end row -->
              <div class="row">
                <div class="col-12">
                  <div class="text-center mt-3 mb-3">
                    <router-link :to="{ name: 'users' }">
                      <button type="button" class="btn w-sm btn-secondary me-5">
                        Cancel
                      </button>
                    </router-link>
                    <button :disabled="isLoading ? true : false" type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-3" @click="
                        currentRouteName === 'user-update'
                          ? updateUser()
                          : createUser()
                        ">
                      <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status"
                        aria-hidden="true"></span>
                      {{ isLoading == true ? 'Loading...' : 'Save' }}
                    </button>
                  </div>
                </div>
                <!-- end col -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { required, sameAs, minLength } from "@vuelidate/validators";
import validationErrors from "../../mixins/validationErrors";

export default {
  setup() {
    const toast = useToast();
    return { v$: useVuelidate(), toast };
  },
  mixins: [validationErrors],
  data() {
    return {
      user: {
        name: "",
        // email: "",
        employee_id: "",
        //   password: "",
        //   confirm_password: "",
        roles: [],
      },
      roles: [],
      isLoading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  validations() {
    return {
      user: {
        name: { required },
        //   email: { required },
        //   employee_id: { required },
        password: { minLength: minLength(6) },
        confirm_password: { sameAs: sameAs(this.user.password) },
        roles: { required },
      },
    };
  },
  methods: {
    getUserById() {
      axios
        .get(`${this.baseUrl}admin/v1/users/${this.$route.params.id}`)
        .then((response) => {
          this.roles = response.data.data.roles;
          this.user.name = response.data.data.user.name;
          // this.user.email = response.data.data.user.email;
          this.user.employee_id = response.data.data.user.employee_id;
          this.user.roles = response.data.data.user.roles.map(
            (role) => role.name
          );
        })
        .catch(() => {
          this.$router.push({ name: "users" });
          this.toast.error("Not Found User!");
        });
    },
    getRoles() {
      axios
        .get(`${this.baseUrl}admin/v1/roles`)
        .then((response) => {
          this.roles = response.data.data.map((role) => role.name);
        })
        .catch(() => {
          this.$router.push({ name: "users" });
          this.toast.error("Not Found Role!");
        });
    },
    async createUser() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;

      this.isLoading = true;

      axios
        .post(`${this.baseUrl}admin/v1/users`, this.user)
        .then(() => {
          this.$router.push({ name: "users" });
          this.toast.success("Successfully User Created!");
          this.isLoading = false;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
          this.isLoading = false;
        });
    },
    async updateUser() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;

      this.isLoading = true;

      axios
        .put(
          `${this.baseUrl}admin/v1/users/${this.$route.params.id}`,
          this.user
        )
        .then(() => {
          this.$router.push({ name: "users" });
          this.toast.success("Successfully User Updated!");
          this.isLoading = false;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
          this.isLoading = false;
        });
    },
  },
  created() {
    if (this.currentRouteName === "user-update") {
      this.getUserById();
    } else {
      this.getRoles();
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>

<style scoped>
.form-control:disabled,
.form-control[readonly] {
  background-color: #efefef;
  opacity: 1;
  cursor: not-allowed;
}
</style>